import React from "react";

const InputWrapper: React.FC<{ label: string }> = ({ label, children }) => (
  <div className="flex flex-col mb-4 font-montserrat flex-1 first:mr-2">
    {label && (
      <label className="text-sm pb-1 font-merriweather text-md">{label}</label>
    )}
    {children}
  </div>
);

export default InputWrapper;
