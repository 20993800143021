import React, { useRef, useState } from "react";

import InputWrapper from "./inputWrapper";

const inputStyle = "p-2 rounded-sm border-eggshell border text-xs";

const ContactForm = () => {
  const formRef = useRef();
  const [didFormSend, setDidFormSend] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    const form = formRef && formRef.current;
    if (form) {
      const formData = new FormData(form);
      const suckaField = formData.get("industry");

      if (suckaField) return;

      const request = new Request(
        "https://getform.io/f/db74c4d8-1463-4c0f-ba79-3ecf8a4e351f",
        {
          method: "POST",
          body: formData,
        }
      );
      fetch(request).then(response => {
        if (response.status === 200) {
          setDidFormSend(true);
        }
      });
    }
  };

  return (
    <div onSubmit={onSubmit}>
      <form ref={formRef}>
        <div className="flex justify-between">
          <InputWrapper label="Name">
            <input
              className={inputStyle}
              name="name"
              placeholder=""
              type="text"
            />
          </InputWrapper>
          <InputWrapper label="Company">
            <input
              className={inputStyle}
              name="company"
              type="text"
              placeholder=""
            />
          </InputWrapper>
        </div>

        <InputWrapper label="Email address">
          <input
            className={inputStyle}
            name="email"
            type="email"
            placeholder=""
          />
        </InputWrapper>

        <InputWrapper label="How can we help?">
          <textarea
            className={inputStyle}
            name="messageBody"
            placeholder="Write your message here..."
          />
        </InputWrapper>

        <input
          className="industry"
          name="industry"
          placeholder="Which industry are you in?"
        />

        <InputWrapper>
          <input
            type="submit"
            className="bg-coral px-2 py-1 self-end shadow-sm w-2/5 rounded-sm bg-coral border-coral uppercase text-sm font-montserrat font-bold text-white"
            value={didFormSend ? "THANKS" : "SUBMIT"}
          />
        </InputWrapper>
      </form>
    </div>
  );
};

export default ContactForm;
