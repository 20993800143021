import React from "react";
import PropTypes from "prop-types";

const Section: React.FC = ({ bgColor, alignment, children, ...props }) => (
  <div
    className={`h-screen ${bgColor && bgColor}
    items-center justify-center
    flex  ${alignment} section text-xs sm:text-base flex-col sm:flex-row`}
    {...props}
  >
    {children}
  </div>
);

Section.propTypes = {
  bgColor: PropTypes.string,
  center: PropTypes.bool,
};

Section.defaultProps = {
  bgColor: "",
  center: false,
};

export default Section;
