import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import debounce from "lodash/debounce";

import Layout from "../components/layout";
import Section from "../components/section";
import Title from "../components/title";
import Paragraph, { ParagraphTitle } from "../components/paragraph";
import ContactForm from "../components/contactForm";

import GreenImage from "../components/greenImage";
import SEO from "../components/seo";

import ClothVideo from "../assets/cloth.mp4";

const IndexPage = () => {
  const [shouldDisplayHeader, setShouldDisplayHeader] = useState(false);

  useEffect(() => {
    const handleScroll = debounce(() => {
      setShouldDisplayHeader(window.scrollY + 200 > window.innerHeight);
    }, 16);

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Layout displayHeader={shouldDisplayHeader}>
      <SEO title="润泰" />
      <Section bgColor="bg-eggshell" center>
        <video className="flex-1" autoPlay loop muted>
          <source src={ClothVideo} type="video/mp4" className="absolute" />
        </video>
        <Title />
      </Section>
      <Section
        center
        bgColor="bg-navyStone text-eggshell"
        alignment="py-48 px-12"
      >
        <Paragraph>
          <div className="mr-6 mt-6 sm:mt-24">
            <ParagraphTitle width="w-48">OUR WORK</ParagraphTitle>
            <p className="mb-8">
              Based in Shaoxing, China, the team at Runtai are experts in
              thoughtfully sourcing textiles for clients and bringing bespoke
              fabric designs to life.
            </p>
            {/* <a className="text-xs uppercase border-b-2 pb-1 border-coral">
              see more
            </a> */}
          </div>

          <div
            className="flex-1"
            style={{
              minWidth: `30%`,
              maxWidth: "200px",
            }}
          >
            <GreenImage />
            {/* <div className="mt-4 text-right">
              <p className="text-xs uppercase inline ">EXPLORE OUR RANGE</p>
            </div> */}
          </div>
        </Paragraph>
      </Section>
      <Section bgColor="bg-eggshell" alignment="py-48 px-12">
        <div className="flex flex-col">
          <div className="flex-1">
            <ParagraphTitle>OUR EXPERTISE</ParagraphTitle>
            <Paragraph position="mb-8">
              <p className="mb-4 ">
                Runtai exclusively focuses on sourcing high quality, light to
                medium weight woven fabrics. We have an extensive range of
                polyester, viscose, ethical, cotton and linen materials, and are
                always looking to improve our offering.
              </p>
              {/* <a className="text-xs uppercase border-b-2 pb-1 border-coral mt-4">
                SEE OUR FABRICS
              </a> */}
            </Paragraph>
          </div>

          <div className="flex-1">
            <ParagraphTitle>OUR SERVICES</ParagraphTitle>
            <Paragraph position="mb-4">
              <p className="mb-4">
                Since its founding in 2010, Runtai has helped countless garment
                factories and brands source and create fabrics in line with a
                variety of custom requirements.
              </p>
              <p className="mb-4">
                Runtai's close ties to local factories that specialise in fabric
                dyeing, digital printing and fabric finishing ensures that it
                has a strong network that enables the team to see any fabric
                design through to production.
              </p>
              <Link
                to="/#contact"
                className="text-xs uppercase border-b-2 pb-1 border-coral"
              >
                ENQUIRE ABOUT services
              </Link>
            </Paragraph>
          </div>
        </div>
      </Section>
      <Section bgColor="bg-mudGreen">
        <div className="py-48 px-12 text-beige">
          <ParagraphTitle>OUR FOCUS ON THE FUTURE</ParagraphTitle>
          <Paragraph position="mb-4">
            <p className="mb-8 sm:text-xl">
              At Runtai, we're not only committed to sourcing and sharing more
              and more sustainable fabrics, but also looking at how we can make
              an impact through our position in the fabric manufacturing and
              garment production supply chain.
            </p>
            <p className="mb-4">
              We're taking steps to ensure that suppliers who share our vision
              have the relevant certifications, and are reliable and committed
              partners.
            </p>
            <p className="mb-4">
              And we're always looking for ways to make our network more green.
              If you're a supplier who cares about sustainable fashion, we're
              always keen to collaborate. Or, if you're simply interested in
              chatting more about our selection of sustainable fabrics, or have
              ideas about how we can improve, we'd love to hear from you.
            </p>
            <Link
              to="/#contact"
              className="text-xs uppercase border-b-2 pb-1 border-coral mt-4"
            >
              GET IN TOUCH
            </Link>
          </Paragraph>
        </div>
      </Section>
      <Section id="contact" bgColor="bg-linen">
        <div className="px-6 sm:px-12 mb-12 contactForm flex-0 sm:flex-1">
          <h3 className="font-bold text-xl sm:text-2xl mb-8 border-b-2 pb-1 border-coral w-64">
            Get in touch
          </h3>
          <ContactForm />
        </div>
        <div className="w-full sm:w-1/2 md:w-1/4 px-12 border-none sm:border-solid sm:border-l-2 sm:border-coral">
          <p className="text-base mb-2 font-bold font-montserrat">Address</p>
          <div className="text-xs mb-4">
            <a href="https://goo.gl/maps/t6Nobbsc6cGUVrsg7" target="_blank">
              Unit 06, 7/F, Shaoxing Longhu Building
              <br />
              No. 17 Ping Jiang Road,
              <br />
              YueCheng District
              <br />
              Shaoxing, Zhejiang
              <br />
              China
              <br />
            </a>
          </div>
          <a
            className="mb-2 text-base font-bold font-montserrat"
            target="_blank"
            href="https://www.linkedin.com/company/shao-xing-run-tai-textile-co-ltd"
          >
            LinkedIn
          </a>
        </div>
      </Section>

      {/* <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
    </Layout>
  );
};

export default IndexPage;
