import React from "react"
import PropTypes from "prop-types"

const Title = () => <h1 className="title text-black">润泰</h1>

Title.propTypes = {
  siteTitle: PropTypes.string,
}

Title.defaultProps = {
  siteTitle: ``,
}

export default Title
